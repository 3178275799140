/*
 * @Description: 个体接口
 */
import {get, postJSON } from '@/utils/request'


export function pageQuery(data) {
    return get('/system/individualOrder/listForH5', data)
}
// 园区
export function selAreaList(data) {
    return get('/basic/data/individualArea/list', data)
}

// 行业类型
export function industryTypes(data) {
    return get('/basic/data/industry/list', data)
}
// 组织形式字典
export function organizationType() {
    return get('/system/dict/data/type/organization_type')
}

// 字号字典
export function nameType() {
    return get('/system/dict/data/type/name_type')
}

// 创建
export function individualCreate(data) {
    return postJSON('/system/individualOrder/create', data)
}

export function ZFById(data) {
    return postJSON('/system/individualOrder/zf', data)
}

export function cancelById(data) {
    return postJSON('/system/individualOrder/cancel/mobile', data)
}